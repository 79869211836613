<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.title"
                placeholder="标题"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-select
                v-model="form.category"
                placeholder="分类"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in categoryList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button @click="$router.push($route.path + '/add')" type="primary"
              >发布新闻</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="标题" data-index="title"> </a-table-column>
        <a-table-column title="摘要" width="540px">
          <template slot-scope="text">
            <div :title="text.brief">
              {{ text.brief ? text.brief.substring(0, 80) : "" }}
            </div>
          </template>
        </a-table-column>

        <a-table-column title="分类" align="center">
          <template slot-scope="text">
            {{ categoryList.find((item) => item.value === text.category).name }}
          </template>
        </a-table-column>

        <a-table-column title="创建日期" data-index="createAt" align="center">
        </a-table-column>

        <a-table-column title="操作" align="center" width="100px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                @click.prevent="
                  $router.push($route.path + '/edit?id=' + text.id)
                "
                >编辑</a
              >
              <a href="#" class="danger" @click.prevent="deleteText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import { fetchList, remove } from "@/api/website/news";
import { categoryList } from "./data";

export default {
  mixins: [watermark],

  data() {
    return {
      form: {
        category: "companyNews",
      },
      categoryList,

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {
        category: "companyNews",
      };
      this.current = 1;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除这篇新闻吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>